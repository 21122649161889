<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <label for="station" :class="{'disabled': tabActive && tabActive.extendedData && tabActive.extendedData.changes}">{{$t("workspace.schedules.station")}}</label>
            <select name="station" id="station" v-model="searchModel.stationId" @change="searchSchedules" :disabled="tabActive && tabActive.extendedData && tabActive.extendedData.changes">
              <option v-for="station in listStations" :key="station.id" :value="station.id">{{station.name}}</option>  
            </select>
          </div>
          <div class="settings-input">
            <label for="day" :class="{'disabled': tabActive && tabActive.extendedData && tabActive.extendedData.changes}">{{$t("workspace.schedules.days_of_week")}}</label>
            <select name="day" id="day" v-model="searchModel.dayOfWeek" @change="searchSchedules" :disabled="tabActive && tabActive.extendedData && tabActive.extendedData.changes">
              <option :value="0">{{$t("days_of_week.sunday")}}</option>
              <option :value="1">{{$t("days_of_week.monday")}}</option>
              <option :value="2">{{$t("days_of_week.tuesday")}}</option>
              <option :value="3">{{$t("days_of_week.wednesday")}}</option>
              <option :value="4">{{$t("days_of_week.thursday")}}</option>
              <option :value="5">{{$t("days_of_week.friday")}}</option>
              <option :value="6">{{$t("days_of_week.saturday")}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="workspace__container__view__container--two">
      <div>
        <div class="workspace__container__view__table__header">
          <h3>{{$t("workspace.schedules.schedules")}}</h3>
          <button class="outline small" @click="createOrEdit('STATION-CONFIG_SHEDULES_COMMERCIAL-BLOCK-CREATE-EDIT')">{{$t("workspace.schedules.add")}}</button>
        </div>
        <table class="workspace__container__view__table">
          <thead>
            <tr>
              <th>{{$t("workspace.schedules.hour")}}</th>
              <th>{{$t("workspace.schedules.ref_hour")}}</th>
              <th>{{$t("workspace.schedules.start_time_logic")}}</th>
              <th>{{$t("workspace.schedules.mix")}}</th>
              <th>{{$t("workspace.schedules.type")}}</th>
              <th class="actions">{{ $t("workspace.station_config.actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(commercialBreak, indexCommercialBreak) in orderBy(schedule.commercialBreaks, 'time')" :key="commercialBreak.id">
              <td>{{commercialBreak.time}}</td>
              <td>{{commercialBreak.timeType ? $t("workspace.schedules.hour_end") : $t("workspace.schedules.hour_init")}}</td>
              <td>{{commercialBreak.toleranceInMinutes ? $t("workspace.schedules.SOFT") : $t("workspace.schedules.HARD")}}</td>
              <td>{{commercialBreak.allowMixSpotCategory ? $t("workspace.schedules.active") : $t("workspace.schedules.disabled")}}</td>
              <td>{{commercialBreak.spotType ? $t("workspace.schedules.type_sponsor") : $t("workspace.schedules.type_spot")}}</td>
              <td class="actions">
                <i class="material-icons" @click="createOrEdit('STATION-CONFIG_SHEDULES_COMMERCIAL-BLOCK-CREATE-EDIT', {...commercialBreak, index: indexCommercialBreak})">edit</i>
                <i class="material-icons" @click="deleteItem(commercialBreak, 'schedule_removeCommercialBreaks')">delete</i>
              </td>
            </tr>
            <tr v-if="schedule.commercialBreaks.length == 0">
              <td colspan="6">
                {{$t('workspace.not_have_loaded')}} <strong>{{ $t("workspace.schedules.schedules") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit('STATION-CONFIG_SHEDULES_COMMERCIAL-BLOCK-CREATE-EDIT')">{{ $t("workspace.schedules.add") }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div>
        <div class="workspace__container__view__table__header">
          <h3>{{$t("workspace.schedules.programs")}}</h3>
          <button class="outline small" @click="createOrEdit('STATION-CONFIG_SHEDULES_SHOWS-CREATE-EDIT')">{{$t("workspace.schedules.add")}}</button>
        </div>
        <table class="workspace__container__view__table">
          <thead>
            <tr>
              <th>{{$t("workspace.schedules.program")}}</th>
              <th>{{$t("workspace.schedules.start_hour")}}</th>
              <th>{{$t("workspace.schedules.end_hour")}}</th>
              <th class="actions">{{ $t("workspace.station_config.actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(show, indexShow) in orderBy(schedule.shows, 'timeStart')" :key="show.id">
              <td>{{showById(show.showId)}}</td>
              <td>{{show.timeStart}}</td>
              <td>{{show.timeEnd}}</td>
              <td class="actions">
                <i class="material-icons" @click="createOrEdit('STATION-CONFIG_SHEDULES_SHOWS-CREATE-EDIT', {...show, index: indexShow})">edit</i>
                <i class="material-icons" @click="deleteItem(show, 'schedule_removeShows')">delete</i>
              </td>
            </tr>
            <tr v-if="schedule.shows.length == 0">
              <td colspan="4">
                {{$t('workspace.not_have_loaded')}} <strong>{{ $t("workspace.schedules.programs") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit('STATION-CONFIG_SHEDULES_SHOWS-CREATE-EDIT')">{{ $t("workspace.schedules.add") }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="workspace__container__view__actions">
      <div>
        <button @click="openModalCopyDay">{{$t("workspace.schedules.copy_day")}}</button>
        <button @click="openModalCopyStation">{{$t("workspace.schedules.copy_station")}}</button>
      </div>
      <button @click="save">{{$t("workspace.schedules.save")}}</button>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  setup() {
    const route = useRoute();
    const store = useStore();
    const listStations = computed(() => store.getters.station_stations({code: '', name: ''}));
    const schedule = computed(() => store.getters.schedule_getSchedule(route.params.id));
    const orderBy = computed(() => (list, property) => list.sort((a, b) => moment(a[property], 'hh:mm').isBefore(moment(b[property], 'hh:mm')) ? -1 : 1));
    const showById = computed(() => id => store.getters.show_programById(id));
    const tabActive = computed(() => store.getters.tabs_getActive);
    const { t } = useI18n();
    const searchModel = ref({
      stationId: 0,
      dayOfWeek: 1
    })

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('station_getAll');
      await store.dispatch('show_getAll');

      if (listStations.value.length > 0) searchModel.value.stationId = listStations.value[0].id;
      
      const extendedData = store.getters.tabs_getActiveExtendedData;
      if ('stationId' in extendedData) searchModel.value = extendedData;
      if (!extendedData.changes) searchSchedules();

      store.dispatch('loading_setActive', false);
    });
    
    async function searchSchedules() {
      if (searchModel.value.stationId) {
        const tabExtendedData = store.getters.tabs_getActiveExtendedData;
        store.dispatch('loading_setActive', true);
        await store.dispatch('shedule_getSchedule', {...searchModel.value, tabId: route.params.id});
        await store.dispatch('tabs_updateTab', {
          id: route.params.id,
          extendedData: {...searchModel.value, changes: tabExtendedData.changes}
        });
        store.dispatch('loading_setActive', false);
      }
    }

    async function createOrEdit(active, item) {
      await store.dispatch('modal_setActive', {active: active, props: item});
    }
    
    async function deleteItem(item, actionDelete) {
      let modelDelete = {...item};
      modelDelete.tabId = route.params.id;
      switch (actionDelete) {
        case 'schedule_removeCommercialBreaks':
          modelDelete.timeType = item.timeType ? t("workspace.schedules.hour_end") : t("workspace.schedules.hour_init");
          modelDelete.toleranceInMinutes = item.toleranceInMinutes ? t("workspace.schedules.SOFT") : t("workspace.schedules.HARD");
          modelDelete.allowMixSpotCategory = item.allowMixSpotCategory ? t("workspace.schedules.active") : t("workspace.schedules.disabled");
          modelDelete.spotType = item.spotType ? t("workspace.schedules.type_sponsor") : t("workspace.schedules.type_spot");
          break;
        case 'schedule_removeShows':
          modelDelete.showId = this.showById(item.showId);
          break;
      }
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: modelDelete, actionDelete});
    }

    async function openModalCopyDay() {
      await store.dispatch('modal_setActive', {active: 'STATION-CONFIG_SCHEDULES_COPY-DAY', props: {station: getStation(searchModel.value.stationId), stationId: searchModel.value.stationId, dayOfWeek: searchModel.value.dayOfWeek}})
    }

    async function openModalCopyStation() {
      await store.dispatch('modal_setActive', {active: 'STATION-CONFIG_SCHEDULES_COPY-STATION', props: {stationId: searchModel.value.stationId}})
    }

    function getStation(id) {
      const station = listStations.value.find(station => station.id == id);
      return station.name
    }

    async function save() {
      const model = {...schedule.value};
      delete model.id;
      await store.dispatch('schedule_update', {
        params: searchModel.value,
        model
      });
      await store.dispatch('tabs_setPropChanges', {id: route.params.id, changes: false});
    }

    watch(() => route.params.id, () => {
      const extendedData = store.getters.tabs_getActiveExtendedData;
      if (route.path.includes('/station_config/schedules/') && 'stationId' in extendedData) {
        searchModel.value = extendedData;
      }
      if (!extendedData.changes) searchSchedules();
    }); 

    return {
      listStations,
      searchModel,
      schedule,
      tabActive,
      showById,
      orderBy,
      createOrEdit,
      deleteItem,
      searchSchedules,
      openModalCopyDay,
      openModalCopyStation,
      save
    }
  }
}
</script>
<style lang="scss" scoped>
  .settings-input {
    min-width: 300px;
  }
</style>